// Section
section {
  padding: 5rem 0rem;

  @include respond(phone) {
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

#navbar {
  background-color: $primary-color;
  padding: 1rem 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 9px;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 15px;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
    }
  }

  .nav-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      margin-top: 1rem;
    }

    li {
      margin-right: 18px;

      @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 10px;
      }

      a {
        color: $white-color;
        text-decoration: none;
        font-size: 20px;
        transition: color 0.3s ease;

        &:hover,
        &:focus {
          color: lighten($secondary-color, 10%);
          text-decoration: underline;
        }
      }
    }
  }


}