#about {
  background-color: $primary-color;
  background-image: linear-gradient(
    135deg,
    $primary-color 0%,
    $secondary-color 100%
  );
  color: $white-color;
  height: 100%;
  border-top: 0px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  padding-bottom: 10%;
  font-size: 18px;

  @include respond(tab-land) {
    height: 100%;
  -webkit-clip-path: none;
clip-path: none;
}

.about-wrapper {
@include respond(phone) {
  padding-bottom: 5rem;
}

&__image {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  max-width: fit-content;
  margin-bottom: 15px;
  

  @include respond(tab-land) {
    height: 100%;
  }
  @include respond(tab-port-sm) {
    padding-bottom: 6.4rem;
  }
}

&__info {
  display: flex;
  height: 100%;
  justify-content: left;
  flex-direction: column;
  padding: 10px; /* Adjust the value as needed */


.about-wrapper__info-text {
  font-size: 1.6rem;
  line-height: 1.6;
    
  @include respond(phone) {
    font-size: 1.4rem;
    }
}

  @include respond(tab-port-sm) {
    align-items: left;
  }

  &-text {
    text-align: left;
    padding: auto;

    @include respond(tab-port) {
      text-align: left;
    }
    @include respond(tab-port-sm) {
      text-align: left;
    }

    &--important {
      background: darken($secondary-color, 1%);
      display: inline-block;
      font-style: italic;
      padding: 0 0.3rem;
      margin: 0.3rem 0;
      line-height: 1.6;

      @include respond(phone) {
        display: inline;
        margin: 0;
        padding: 0;
        background: transparent;
        font-style: normal;
      }
    }
  }
}




button {
  font-size: 15px; // Makes the font larger. Adjust as needed.
  color: $white-color; // Use your predefined color variable for text color.
  background-color: darken($secondary-color, 10%); // Darker version of the secondary color.
  border: none; // Removes the border.
  padding: 0.5em 1em; // Adjust padding to your liking.
  cursor: pointer; // Changes cursor to pointer on hover.
  transition: background-color 0.3s ease; // Smooth transition for background color on hover.

  &:hover {
    background-color: lighten($secondary-color, 10%); // Lightens the button on hover for feedback.
  }
}
}
}
